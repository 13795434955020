<template>
    <viewport>
    <div>
        
        <div class="loading-msg">
            <div class="loading-dots" v-if="!Pcwxlogin">
                <span class="fi fi-rr-eyes"></span>
            </div>
            <div>
                <span>{{ loginmsg }}</span>
            </div>
        </div>
    </div>
    </viewport>
</template>
<script>
export default {
    data() {
        return {
            Pcwxlogin: false,
            loginmsg: "正在加载登录信息"
        }
    },
    mounted() {
        const query = this.$route.query;
        let timesTime = query.timesTime;

        if (timesTime===undefined) {
            const PageUrl = process.env.VUE_APP_BASE_HTTP_URL + "webWxlogin"
            const currentPageUrl = encodeURIComponent(PageUrl);
            window.location.href = process.env.VUE_APP_BASE_URL+"GetWxcode?b_url=" + currentPageUrl
        } else {
            //取出timesTime

            this.$http.get('PcWeXinLogin?timesTime=' + timesTime).then((res) => {
                console.log(res.data)
                if (res.data.code === 200) {
                    localStorage.setItem("UserD", JSON.stringify(res.data))
                    this.$router.replace({ path: '/chat' });
                } else {
                    this.Pcwxlogin = true
                    this.loginmsg = res.data.msg
                }
            }).catch((err) => {
                console.log(err.message)
                this.Pcwxlogin = true
                this.loginmsg = err.message
            })
        }
    },



}
</script>
<style>
.loading-msg {
    max-width: 768px;
    position: absolute;
    top: 50%;
    left: 50%;
    /* 添加此属性以使元素在水平方向上居中 */
    transform: translate(-50%, -50%);
    /* 添加此属性以校正元素的位置 */
    text-align: center;
    z-index: 9999;
}


.loading-dots {
    width: 100%;

}

.loading-dots span {
    font-size: 36px;
    display: inline-block;
    animation: loading 1.2s ease-in-out infinite;
    color: #4c4d4f;
    /* 设置文本颜色 */
}

.loading-dots span:nth-child(1) {
    animation-delay: 0.3s;
}



@keyframes loading {
    0% {
        transform: scale(0.2);
        opacity: 0.2;
    }

    50% {
        transform: scale(1.0);
        opacity: 1.0;
    }

    100% {
        transform: scale(0.2);
        opacity: 0.2;
    }
}
</style>