<template>
    <viewport>
    <div class="loginbody">
        <div ref="p5"></div>
        <div class="login-container">
            <div class="headH1">
                <h1>DepNB</h1>
            </div>
            <!-- <input type="text" placeholder="输入手机号" name="username" required>
            <div class="sms-code-container">
                <input type="number" id="sms-code" name="sms-code" placeholder="输入短信验证码" required>
                <button type="button" class="send-code">发送短信验证码</button>
            </div>
            <button type="button" class="login_button">开启DepNB</button> -->
            <div class="qrcode">
                <img v-show="!LoginMsgShow" :src="LoginQRcodeUrl" alt="YAFA科技公众号二维码">
                <div v-show="LoginMsgShow" class="qrcode-msg">
                    <span>{{ LoginMsgtext }}</span>
                </div>
            </div>
            <div class="bottom-text">
                微信扫一扫免注册登录
                <span>手机端使用 建议前往【<a class="">YAFA科技</a>】微信公众号</span>
            </div>

        </div>
    </div>
</viewport>
</template>
  
<script>
import p5 from 'p5';
class Particle {
    constructor(sketch) {
        this.sketch = sketch;
        this.x = this.sketch.random(this.sketch.width);
        this.y = this.sketch.random(this.sketch.height);
        this.size = this.sketch.random(0.5, 4.5);
        this.speedX = this.sketch.random(-0.9, 0.9);
        this.speedY = this.sketch.random(-0.5, 1.5);
    }
    update() {
        this.x += this.speedX;
        this.y += this.speedY;
        if (this.x < 0) this.x = this.sketch.width;
        if (this.x > this.sketch.width) this.x = 0;
        if (this.y < 0) this.y = this.sketch.height;
        if (this.y > this.sketch.height) this.y = 0;
    }
    draw(sketch) {
        sketch.noStroke();
        sketch.fill('rgba(51, 102, 255, 0.6)');
        sketch.ellipse(this.x, this.y, this.size);
    }
}

export default {
    name: 'LoginComponent',
    data() {
        return {
            particles: [],
            LoginQRcodeUrl: "../assets/depnb.png",
            LoginTimesCode: "",
            LoginMsgShow: false,
            LoginMsgtext: "二维码加载中...",
            POLLING_INTERVAL: 1000,
            Login:false
        };
    },
    mounted() {
        new p5(sketch => {
            sketch.setup = () => {
                sketch.createCanvas(window.innerWidth, window.innerHeight);
                for (let i = 0; i < 100; i++) {
                    this.particles.push(new Particle(sketch));
                }
            };
            sketch.draw = () => {
                sketch.background(30, 31, 33);
                for (let particle of this.particles) {
                    particle.update();
                    particle.draw(sketch);
                }
            };
        }, this.$refs.p5);


        let varua = navigator.userAgent.toLowerCase();
        if (varua.match(/MicroMessenger/i) != "micromessenger") {
            this.Pcowx = true
            this.ReadWeXinQrcodeL()
        } else {
            console.log("微信进入")
            this.$router.replace({ path: '/chat' });
        }

        
    },


    methods: {
        ReadWeXinQrcodeL() {
            this.LoginMsgShow = true
            this.$http.get('GetWeiXinQrcodeL').then((res) => {
                console.log(res.data)
                if (res.data.msg === "success") {
                    this.LoginQRcode = res.data.msg
                    this.LoginTimesCode = res.data.timesTime
                    this.WeiXinQR_SCENE()
                }
            }).catch((err) => {
                console.log(err)
                this.LoginMsgShow = false
            })
        },
        WeiXinQR_SCENE() {
            this.LoginQRcodeUrl = process.env.VUE_APP_BASE_URL+"WeiXinQR_SCENE?timesTime=" + this.LoginTimesCode
            this.LoginMsgShow = false
            this.LoginPoll()
        },

        //写轮询
        LoginPoll() {
            let _this = this
            if(_this.Login){
                _this.POLLING_INTERVAL = 0
                return
            }
            _this.$http.get('PcWeXinLogin?timesTime='+_this.LoginTimesCode).then((res) => {
                console.log(res.data)
                if (res.data.code === 200) {
                    _this.POLLING_INTERVAL = 0
                    _this.Login = true
                    _this.LoginMsgtext = "扫码成功 正在登录"
                    _this.LoginMsgShow = true
                    localStorage.setItem("UserD",JSON.stringify(res.data))
                    _this.$router.replace({ path: '/chat' });
                } else if(res.data.code === 201) {
                    _this.LoginMsgtext = "二维码过期了 请刷新页面"
                    _this.LoginMsgShow = true
                    _this.POLLING_INTERVAL = 0
                } else{
                    setTimeout(_this.LoginPoll, _this.POLLING_INTERVAL);
                }
            }).catch((err) => {
                console.log(err.message)
                setTimeout(_this.LoginPoll, _this.POLLING_INTERVAL);
            })
        }
    }
};
</script>
  
<style>
/* Add your existing CSS styles here */
.loginbody {
    margin: 0;
    padding: 0;
    overflow: hidden;
    position: relative;
    height: 100vh;
    width: 100%;
}

#particles {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    z-index: 0;
}

.login-container {
    background-color: rgba(41, 43, 45, 0.6);
    width: 86%;
    max-width: 460px;
    border-radius: 10px;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}


.logo {
    width: 120px;
    margin-bottom: 20px;
}

input[type=text],
input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 5px;
    font-size: 14px;
}

.login_button {
    background-color: #3366ff;
    color: white;
    padding: 14px 20px;
    margin: 36px 0 8px 0;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 5px;
    font-size: 16px;

}

.login_button:hover {
    opacity: 0.8;
}

.bottom-text {
    color: #eee;
    margin-top: 36px;
    font-size: 14px;
    text-align: center;

}

.bottom-text span {
    font-size: 12px;
    display: block;
    margin-top: 12px;
    color: #666;
}

.bottom-text a {
    color: #3366ff;
    text-decoration: none;
}

.bottom-text a:hover {
    text-decoration: underline;
}

.send-code {
    background-color: #3366ff;
    color: white;
    padding: 12px 20px;
    margin: 8px 0;
    border: none;
    cursor: pointer;
    width: auto;
    border-radius: 0px 5px 5px 0px;
    float: right;
    font-size: 14px;
}

.send-code:hover {
    opacity: 0.8;
}

.sms-code-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.sms-code-container input[type=number] {
    flex-grow: 1;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 5px 0 0px 5px;
    font-size: 14px;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    appearance: textfield;
    -moz-appearance: textfield;
}

.qrcode {
    background-color: #FFF;
    /* box-shadow: #3366ff 0px 0px 16px; */
    width: 260px;
    height: 260px;
    margin: auto;
    border-radius: 10px;
    padding: 10px;
    position: relative;
}

.qrcode img {
    width: 240px;
    height: 240px;
    border-radius: 5px;
}

.qrcode-trigger:hover~.qrcode {
    display: block;
}


.qrcode-msg {
    position: absolute;
    margin: 0 auto;
    width: 240px;
    height: 240px;
    top: 0;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.8);
}

.qrcode-msg span {
    width: 100%;
    text-align: center;
    display: block;
    padding-top: 120px;
    color: #3366ff;
}

.headH1 {
    margin-bottom: 30px;

}

.headH1 h1 {
    color: #FFF;
    text-shadow: #3366ff 1px 1px 6px;
    ;

}
</style>
  