import axios from 'axios'
var baseUrl = process.env.VUE_APP_BASE_URL
const service = axios.create({
	baseURL: baseUrl, // api的base_url
	timeout: 12000 // 请求超时时间
});



service.interceptors.response.use(
    response => {  //成功请求到数据
		return Promise.resolve(response)
    },
    error => {  //响应错误处理
        console.log('error');
        console.log(error);
        return Promise.reject(error)
    }
);

export default service