import Vue from "vue";
import Router from "vue-router"
import Chat from "../components/Chat.vue"
import Login from "../components/LoginComponent.vue"
import WebWxLogin from "../components/webWxlogin.vue"
Vue.use(Router)
export default new Router({
    base: process.env.BASE_URL,
    mode: "history",
    routes: [
        {
            path: '/webWxlogin',
            name: 'webWxlogin',
            component: WebWxLogin
        },
        {
            path: '/',
            name: 'login',
            component: Login
        },
        {
            path: '/login',
            name: 'login',
            component: Login
        },
        {
            path: '/chat',
            name: 'chat',
            component: Chat
        },
    ]
});