import service from "../api/api"
const baseUrl = process.env.VUE_APP_BASE_URL
const baseUrws = process.env.VUE_APP_BASE_WS_URL
const post = (url,data,h) =>{
	return service({
		url:url,
		method:"post",
		headers:h,
		data
	})
}

const get = (url,h) => {
	return service({
		url: url,
		method: "get",
		headers:h,
	})
}

export default{
    post,
    get,
	baseUrl,
	baseUrws
}