import Vue from 'vue';
import App from './App.vue';
import router from '../src/router/index';
import http from "../src/api/http";
import h from "../src/api/h";
import infoPopupPlugin from "./components/common/js/infoPopupPlugin";
import VueViewport from 'vue-viewport';
import hljs from 'highlight.js/lib/core'
import javascript from 'highlight.js/lib/languages/javascript'
import 'highlight.js/styles/github.css'

hljs.registerLanguage('javascript', javascript)

Vue.directive('highlight', {
  inserted: function(el) {
    hljs.highlightBlock(el)
  }
})

Vue.use(VueViewport);
Vue.use(infoPopupPlugin);
Vue.prototype.$http = http;
Vue.prototype.$h = h;
Vue.config.productionTip = false;








new Vue({
  router,
  render: h => h(App),
}).$mount('#app')