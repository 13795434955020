<template>
    <viewport>
        <div class="Chatbody">
            <div class="main">
                <div class="menu" :class="{ show: menuVisible }">
                    <div class="head">
                        <button class="newchat" @click="NewmsgkeyshowModal = !NewmsgkeyshowModal">
                            <span class="fi fi-rr-plus"></span>
                            <span class="newchatspan">新建聊天窗口</span>
                        </button>
                        <div class="menu-close-button" @click="closeMenu">&times;</div>
                    </div>

                    <div style="text-align: center; margin-top: 26px;" v-if="ChatListLoad">
                        <span class="fi fi-rr-bullet message-row-load"></span>
                        <span class="fi fi-rr-bullet message-row-load"></span>
                        <span class="fi fi-rr-bullet message-row-load"></span>
                    </div>
                    <nav>
                        <ul>
                            <li :class="sendmsgkeyindex === index ? 'menu-li-click' : ''"
                                v-for="(item, index) in sendmsgkey" :key="index">
                                <div class="menu-items" @click="clickmsgkey(index)">
                                    <div>
                                        <span class="fi fi-rr-comment-alt-dots"></span>
                                        <a>{{ $h.truncateText(item.key_name, 16) }}</a>
                                    </div>

                                </div>

                                <div class="delmsgkey" @click="delmsgkey(item.key_id)" v-if="sendmsgkeyindex === index">
                                    <span class="fi fi-rr-trash"></span>
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <div class="mainLeftBottom">
                        <nav class="menuBottom">
                            <ul>
                                <!-- <li>
                                    <div class="menu-item">
                                        <span class="fi fi-rr-trash"></span>
                                        <a>清空列表</a>
                                    </div>
                                </li> -->
                                <li @click="RoleshowModal = !RoleshowModal">
                                    <div class="menu-item">
                                        <span class="fi fi-rr-users-alt"></span>
                                        <a>选择助理</a>
                                    </div>
                                    <div class="New"
                                        style="border-radius: 8px; text-align: center; margin: 8px auto; background-color: #3366ff;color: #eee; padding: 1px 4px; align-items: center; display: flex;">
                                        New
                                    </div>
                                </li>
                                <li @click="AccountMsg">
                                    <div class="menu-item">
                                        <span class="fi fi-rr-user-pen"></span>
                                        <a>我的账号</a>
                                    </div>
                                </li>
                                <li @click="usingHelp">
                                    <div class="menu-item">
                                        <span class="fi fi-rr-interrogation"></span>
                                        <a>使用帮助</a>
                                    </div>
                                </li>
                                <li @click="LoginOutshowModal = !LoginOutshowModal" v-if="Pcowx">
                                    <div class="menu-item">
                                        <span class="fi fi-rr-address-card"></span>
                                        <a>退出登录</a>
                                    </div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="chat-container">
                    <div class="chat-header">
                        <h3 style="width: 25%; text-align: left;">DepNB</h3>
                        <span style="width: 50%; text-align: center;">{{ clickname }}</span>
                        <span style="width: 25%; text-align: right;" class="fi fi-rr-list chat-header-menu-icon"
                            @click="toggleMenu"></span>
                    </div>
                    <div class="chat-content">
                        <div class="message-container">

                            <!-- 聊天记录将显示在这里 -->
                            <div style="text-align: center; margin-top: 26px;" v-if="msgload">
                                <span class="fi fi-rr-bullet message-row-load"></span>
                                <span class="fi fi-rr-bullet message-row-load"></span>
                                <span class="fi fi-rr-bullet message-row-load"></span>
                            </div>

                            <div v-for="(assistant, u) in sendmsg" :key="u">
                                <div class="message-row" v-if="assistant.role == 'user'">
                                    <div class="message message-sent">
                                        <p class="message-text multiline-text">{{ assistant.content }}</p>
                                    </div>
                                    <div><img :src="headimgurl" alt="User Avatar" class="avatar" v-if="headimgurl !== ''">
                                    </div>
                                    <span class="fi fi-rr-face-sunglasses avatar" style="font-size: 2.6rem; color: #eee;"
                                        v-if="headimgurl === ''"></span>
                                </div>

                                <div class="message-row" v-if="assistant.role == 'assistant'">
                                    <img src="../assets/depnb.png" alt="ChatGPT Avatar" class="avatar">

                                    <div class="message message-received">


                                        <div class="message-text multiline-text"
                                            v-html="highlightCodeBlocks(assistant.content)" :id="u + '-my-text'"></div>
                                        <div class="msgcopy" @click="copyText(u)">
                                            <span class="fi fi-rr-copy-alt"></span> Copy
                                        </div>
                                    </div>


                                </div>

                            </div>
                            <div class="message-row" v-if="sendload">
                                <img src="../assets/depnb.png" alt="ChatGPT Avatar" class="avatar">
                                <div class="message message-received">
                                    <p class="message-text ">
                                        <span class="fi fi-rr-bullet message-row-load"></span>
                                        <span class="fi fi-rr-bullet message-row-load"></span>
                                        <span class="fi fi-rr-bullet message-row-load"></span>
                                    </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="chat-footer">
                        <div class="prompt" @click="RoleshowModal = !RoleshowModal">
                            当前助理：<span>{{ Promptlist[ClickPrompt].name }}</span>
                        </div>
                        <div class="input-form">

                            <div class="footer-menu" @click="toggleMenu">
                                <span class="fi fi-rr-chart-simple-horizontal"></span>
                            </div>
                            <textarea v-model="something" type="text" class="input-message"
                                :placeholder="Pcowx ? '这里输入问题消息 Pc端可按Shift+Return发送信息' : '这里输入问题消息'" ref="textarea" rows="1"
                                @keydown.shift.enter.prevent="WsSend"></textarea>
                            <button class="send-button" @click="WsSend" :disabled="sendloads">
                                <span class="fi fi-rr-paper-plane-top"></span>
                            </button>
                        </div>
                    </div>
                </div>








                <div class="errmsg" v-if="errload">
                    <div>
                        <span class="fi fi-rr-info" style="font-size: 1rem; color: red; margin-right: 10px;"></span>
                        {{ errmsg }}
                    </div>
                    <span class="fi fi-rr-cross-circle" @click="errload = !errload"></span>

                </div>
            </div>




            <RoleModal :show.sync="RoleshowModal" title="选择专业助理">

                <div class="roles">
                    <div class="roleslist" v-for="(item, index) in Promptlist" :key="index"
                        @click="ClickPrompt = item.Id, RoleshowModal = !RoleshowModal"
                        :class="ClickPrompt === item.Id ? 'clickrole' : ''">
                        <div>
                            {{ item.name }}
                        </div>
                        <span v-if="ClickPrompt === item.Id">{{ item.introduce }}</span>
                    </div>
                </div>

            </RoleModal>










            <CustomModal :show.sync="LoginOutshowModal" title="提醒" :showButtons="true" confirmButtonText="确认"
                cancelButtonText="取消" @confirm="logout" @cancel="LoginOutshowModal = !LoginOutshowModal">
                <div>是否要退出登录 ?</div>
            </CustomModal>
            <CustomModal v-if="sendmsgkey.length > 0" :show.sync="DelmsgkeyshowModal" title="提醒" :showButtons="true"
                confirmButtonText="确认" cancelButtonText="取消" @confirm="delmsgkey_handleConfirm"
                @cancel="DelmsgkeyshowModal = !DelmsgkeyshowModal">
                <div>是否要删除 {{ sendmsgkey[sendmsgkeyindex].key_name }} 窗口?</div>
            </CustomModal>

            <CustomModal :show.sync="NewmsgkeyshowModal" title="新建聊天会话窗口" :showButtons="true" confirmButtonText="确认"
                cancelButtonText="取消" @confirm="newmsgkey" @cancel="NewmsgkeyshowModal = !NewmsgkeyshowModal">
                <div>
                    <input v-model="NewmsgKeyName" placeholder="新建聊天窗" type="text" class="input-message">
                </div>
            </CustomModal>


            <CustomModal :show.sync="AccountShowModal" title="账号信息" :showButtons="false" confirmButtonText="确认"
                cancelButtonText="取消" @confirm="AccountMsg" @cancel="AccountShowModal = !AccountShowModal">
                <div>
                    <div class="headmodal">
                        <img :src="headimgurl" alt="User Avatar" class="avatar">
                    </div>
                    <div class="accmsg">
                        <span>类型:</span>
                        <span>{{ UserD.userType == 0 ? '免费用户' : UserD.userType == 1 ? '计次付费' : UserD.userType == 2 ?
                            'tokens付费' : UserD.userType == 3 ?
                                '包月付费' : '' }}</span>
                    </div>
                    <div class="accmsg" v-if="UserD.userType !== 3">
                        <span>剩余:</span>
                        <span>{{ UserChatNumber }}次</span>
                    </div>
                    <div class="accmsg" v-if="UserD.userType === 3">
                        <span>到期:</span>
                        <span>{{ $h.timestampToTime(UserChatNumber, 1) }}</span>
                    </div>

                    <div class="accountButton">
                        <button @click="AccountShowModal = !AccountShowModal, PayShowModal = !PayShowModal, getQRCode()">
                            购买服务
                        </button>
                    </div>
                </div>
            </CustomModal>


            <CustomModal :show.sync="PayShowModal" title="购买服务" :showButtons="false">
                <div class="paymodal">
                    <div class="paymodal-head">

                        <h3>{{
                            selectedOption == 'monthly' ? '包月' : selectedOption == 'count_300' ? '计300次' : selectedOption ==
                                'count_100' ?
                                '计100次' : selectedOption == 'count_50' ? '计50次' : '' }}
                        </h3>
                        <div class="paymodal-price">¥{{ PaylabelPrice[clickPriceIndex].value }}</div>
                    </div>
                    <div class="paymodal-qrcode" v-if="Pcowx">
                        <qrcode-vue :size="100" :value="qrCodeValue" v-if="qrCodeValue != ''"></qrcode-vue>
                        <div class="paymodal-qrcode-text" v-if="qrCodeValue == ''">
                            <span class="fi fi-rr-bullet message-row-load"></span>
                            <span class="fi fi-rr-bullet message-row-load"></span>
                            <span class="fi fi-rr-bullet message-row-load"></span>
                        </div>
                        <div style="margin-top: 10px; color:  #8d8e91;">请使用微信扫一扫付款</div>
                    </div>
                    <div class="paymodal-labellist">
                        <label v-for="(item, index) in PaylabelPrice" :key="index" :for="index"
                            :class="selectedOption == item.name ? 'paymodal-labelC' : 'paymodal-label'">
                            <input type="radio" :id="index" :name="item.name" :value="item.name" v-model="selectedOption"
                                @change="getQRCode" @click="clickPriceIndex = index" :disabled="PaybuttonDis">
                            {{ item.label }}
                        </label>

                    </div>

                </div>
                <div class="accountButton" v-if="!Pcowx">
                    <button @click="weixinPay" :disabled="PaybuttonDis">
                        {{ Paybuttontext }}
                    </button>
                </div>
            </CustomModal>





            <div class="delp" @click="delmsgkey(sendmsgkey[sendmsgkeyindex].key_id)">
                <span class="fi fi-rr-trash"></span>
            </div>

        </div>
    </viewport>
</template>
  
<script>

import CustomModal from "./common/CustomModal.vue";
import RoleModal from "./common/RoleModal.vue";
import qrcodeVue from "qrcode-vue";
import hljs from 'highlight.js';
import 'highlight.js/styles/isbl-editor-dark.css';
let jWeixin = require('jweixin-module');

export default {
    name: 'ChatApp',
    components: {
        CustomModal,
        RoleModal,
        qrcodeVue
    },
    data() {
        return {
            Promptlist: [
                {
                    name: "默认角色",
                    Id: 0,
                    introduce: "默认语言模型",
                },
            ],
            ClickPrompt: 0,
            Paybuttontext: '确认支付',
            PaybuttonDis: false,
            dataUrl: null,
            PaylabelPrice: [{
                name: 'monthly',
                label: '包月',
                value: '168',
            }, {
                name: 'count_300',
                label: '300次',
                value: '98',
            }, {
                name: 'count_100',
                label: '100次',
                value: '36',
            }, {
                name: 'count_50',
                label: '50次',
                value: '20',
            }],
            clickPriceIndex: 0,
            selectedOption: "monthly",
            UserD: {
                userType: 0,
            },
            UserChatNumber: 0,
            Pcowx: false,
            headimgurl: "",
            websocketOpen: false,
            websocket: null,
            something: "",
            token: "",
            sendmsghieghttext: '',
            sendmsg: [],
            sendmsgkey: [],
            sendload: false,
            sendloads: false,
            sendmsgkeyindex: 0,
            DelmsgkeyshowModal: false,
            NewmsgkeyshowModal: false,
            LoginOutshowModal: false,
            RoleshowModal: false,
            NewmsgKeyName: "",
            delkeyid: "",
            clickname: "",
            ChatListLoad: false,
            errload: false,
            errmsg: '',
            msgload: false,
            menuVisible: false,
            AccountShowModal: false,
            PayShowModal: false,
            qrCodeValue: '',
            wxConfig: false
        }
    },

    mounted() {

        // this.$nextTick(() => {
        //     // 初始化时自适应高度
        //     this.autoResize();

        // });

        let UserDs = null
        let UserdString = localStorage.getItem("UserD")
        if (this.$h.isValidJSON(UserdString)) {
            UserDs = JSON.parse(UserdString)
        }

        let varua = navigator.userAgent.toLowerCase();
        if (varua.match(/MicroMessenger/i) != "micromessenger") {
            this.Pcowx = true
            console.log("pc进入", UserDs)
            if (UserDs === null || UserDs.token === "") {
                //跳回登录页面
                this.$router.replace({ path: '/login' });
            }
        } else {
            console.log("微信进入")
            if (UserDs === null || UserDs.token === "") {
                //重新请求登录
                this.$router.replace({ path: '/webWxlogin' });
            }
        }
        this.token = UserDs.token;
        this.UserD = UserDs;
        this.headimgurl = UserDs.headimgurl;
        
        this.redcontextlist()

        this.websocket = new WebSocket(this.$http.baseUrws);

        this.websocket.onerror = (err) => {

            this.errload = true
            this.errmsg = "Ai服务连接失败 请检查网络环境" + err
        };

        this.websocket.onopen = (event) => {
            console.log(event);
            this.websocketOpen = true;
            this.websocket.send(this.token);

        };
        this.websocket.onclose = (event) => {
            console.log('WebSocket connection closed:', event);
            // 服务器连接断开了
            this.websocketOpen = false;
            this.errload = true
            this.errmsg = "Ai服务断开连接 请重新加载页面"
        };
        this.websocket.onmessage = async (event) => {
            if (event.data !== null) {
                let data = JSON.parse(event.data)
                if (data.code === 4001) {
                    if (this.Pcowx) {
                        this.$router.replace({ path: '/login' });
                    } else {
                        this.$router.replace({ path: '/webWxlogin' });
                    }
                    return
                }
                if (data.code === 201) {
                    //服务器推送了会话列表

                }
                if (data.code === 4000) {
                    this.errmsg = data.errmsg
                    this.errload = true
                    return
                }
                if (data.code === 601) {
                    this.PayShowModal = !this.PayShowModal
                    this.$showInfoPopup("支付成功", 6000);
                    return
                }
                if (data.code === 200) {
                    if (data.response === "[start]") {
                        console.log(data.response)
                        setTimeout(() => {
                            this.sendload = false
                        }, 200)
                        let d = {
                            role: "assistant",
                            content: "",
                        };
                        this.sendmsg.push(d);
                    }

                    let responseText = data.response;
                    if (responseText !== "" && responseText !== "[start]" && responseText !== "[DONE]") {
                        await this.sleep(120); // 设置每个字符的显示延迟，这里为 50 毫秒
                        this.appendCharacter(responseText);

                    }
                    if (responseText === "[DONE]") {
                        console.log(data.response)
                        setTimeout(() => {
                            this.sendloads = false
                        }, 100)
                    }


                }
            }
        };
        if (!this.wxConfig && !this.Pcowx) {
            this.ConfigWx()
        }

        if (this.UserD.userType == 1 || this.UserD.userType == 0) {
            this.selectedOption = "count_300"
            this.clickPriceIndex = 1
        }
        if (this.UserD.userType == 3) {
            this.selectedOption = "monthly"
        }
        //Prism.highlightAll()
        this.$refs.textarea.addEventListener('input', () => {
            this.$refs.textarea.style.height = 'auto';
            this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
        });
        this.$refs.textarea.addEventListener('blur', () => {
            this.$refs.textarea.style.height = 'auto';
            this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 'px';
        });
        this.GetWebPromptS()
    },

    methods: {
        appendCharacter(data) {
            //this.sendmsghieghttext += data;
            this.sendmsg[this.sendmsg.length - 1].content += data;
            this.scrollToBottom();
        },

        sleep(ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        },
        highlightCodeBlocks(text) {
            //高亮代码
            const codeRegex = /```(\w+)\n([\s\S]*?)\n```/g;
            return text.replace(codeRegex, (match, p1, p2) => {
                if (!hljs.getLanguage(p1)) {
                    // 如果指定的语言不支持，则使用纯文本格式
                    return `${p2}`;
                }
                const highlightedCode = hljs.highlight(p1, p2).value;
                return `<div style="color: #8d8e91;">${p1}</div><pre class="highlight"><code class="${p1}">${highlightedCode}</code></pre>`;
            });
        },


        GetWebPromptS() {
            //获取专业角色数据

            this.$http.post("httpchat/GetWebPromptS", {
                "token": this.token
            }).then((res) => {
                console.log(res.data)
                if (res.data.code === 200) {

                    for (let index = 0; index < res.data.promptlist.length; index++) {
                        this.Promptlist.push(res.data.promptlist[index])

                    }
                } else {
                    this.errmsg = res.data.errmsg
                    this.errload = true
                }
            }).catch((err) => {
                console.log(err.message)
                this.errmsg = err.message
                this.errload = true
            })
        },
        getQRCode() {
            //获取pc端支付二维码
            this.qrCodeValue = ''
            if (this.Pcowx) {
                //pc支付 native
                this.PaybuttonDis = true
                this.$http.get("pay/chat?payType=" + this.selectedOption, { "Authorization": this.token }).then((res) => {
                    console.log(res)
                    if (res.data.code !== 4000) {
                        this.qrCodeValue = res.data.CodeUrl.code_url
                    } else {
                        this.errmsg = res.data.errmsg
                        this.errload = true
                    }
                    this.PaybuttonDis = false
                }).catch((err) => {
                    console.log(err)
                    this.errmsg = err
                    this.errload = true
                    this.PaybuttonDis = false
                })
            }
        },
        ConfigWx() {
            //初始化微信JSSDK配置
            const _this = this
            const currentPageUrl = encodeURIComponent(window.location.href);
            _this.$http.get("chatgpt/redjsSdkConfig?currentURL=" + currentPageUrl, { "Authorization": _this.token }).then((res) => {
                jWeixin.config({
                    debug: false,
                    appId: res.data.appId,
                    timestamp: res.data.timestamp,
                    nonceStr: res.data.nonceStr,
                    signature: res.data.signature,
                    jsApiList: ["chooseWXPay"]
                });
                jWeixin.ready(function () {
                    console.log('初始化成功')
                    _this.wxConfig = true
                });
                jWeixin.error(function (err) {
                    console.log('初始化失败')
                    _this.errmsg = "微信 JS-SDK 配置失败：" + err
                    _this.errload = true
                });

            }).catch((err) => {
                console.log(err)
                _this.errmsg = err
                _this.errload = true
            })
        },
        weixinPay() {
            //唤起支付
            if (!this.wxConfig) {
                this.errmsg = "微信支付SDK配置失败"
                this.errload = true
                return
            }
            this.Paybuttontext = "正在发起支付"
            this.PaybuttonDis = true
            this.$http.get("chatgpt/redPayParams?payType=" + this.selectedOption, { "Authorization": this.token }).then((res) => {
                console.log(res)
                if (res.data.code !== 4000) {
                    jWeixin.chooseWXPay({
                        timestamp: res.data.timeStamp,
                        nonceStr: res.data.nonceStr,
                        package: res.data.package,
                        signType: res.data.signType,
                        paySign: res.data.paySign,
                        success: function (res) {
                            // 支付成功后的回调函数
                            console.log("支付成功：", res);
                            //进入结果页面后，请求服务器验证支付结果。
                            this.Paybuttontext = "确认支付"
                            this.PaybuttonDis = false
                        },
                        fail: function (res) {
                            // 支付失败后的回调函数
                            console.log("支付失败：", res);
                            //进入结果页面，展示失败结果。
                            this.Paybuttontext = "确认支付"
                            this.PaybuttonDis = false
                        },
                        cancel: function (res) {
                            // 取消支付后的回调函数
                            console.log("取消支付：", res);
                            //提示用户，稍后在订单中心继续支付。
                            this.Paybuttontext = "确认支付"
                            this.PaybuttonDis = false
                        }
                    });
                } else {
                    this.errmsg = res.data.errmsg
                    this.errload = true
                    this.Paybuttontext = "确认支付"
                    this.PaybuttonDis = false
                }
            }).catch((err) => {
                console.log(err)
                this.errmsg = err
                this.errload = true
                this.Paybuttontext = "确认支付"
                this.PaybuttonDis = false
            })
        },
        AccountMsg() {
            this.AccountShowModal = !this.AccountShowModal
            this.$http.get("chatgpt/redremaining", { "Authorization": this.token }).then((res) => {
                console.log(res)
                if (res.data.code === 200) {
                    this.UserD.userType = res.data.userType
                    this.UserChatNumber = res.data.number
                }
            }).catch((err) => {
                console.log(err)
            })

        },
        WsSend() {
            //发送信息
            if (this.something === "") {
                return
            }
            if (this.websocket.readyState === WebSocket.OPEN) {
                if(this.something.length > 2000){
                    this.$showInfoPopup("超过长度限制 最大2000")
                    return
                }
                let d = {
                    role: "user",
                    content: this.something,
                    type: "ChatGPT",
                    token: this.token,
                    keyid: this.sendmsgkey[this.sendmsgkeyindex].key_id,
                    keyname: this.sendmsgkey[this.sendmsgkeyindex].keyname,
                    n: 1,
                    clickPrompt: this.ClickPrompt
                };
                if (this.sendmsg === null) {
                    this.sendmsg = [d]
                } else {
                    this.sendmsg.push(d)
                }
                this.websocket.send(JSON.stringify(d))
                this.something = ""
                this.sendload = true
                this.sendloads = true
                this.autoResize()
                this.scrollToBottom()
            } else {
                console.log('WebSocket connection is not open. Cannot send message.');
            }
        },
        scrollToBottom() {
            this.$nextTick(() => {
                window.scrollTo(0, document.body.scrollHeight);
            });
        },
        clickmsgkey(e) {
            //选中会话
            this.menuVisible = false
            this.sendmsgkeyindex = e
            this.clickname = this.sendmsgkey[e].key_name
            this.msgload = true
            this.sendmsg = []
            this.$http.post("httpchat/AddContextMsg", {
                "token": this.token,
                "key_id": this.sendmsgkey[this.sendmsgkeyindex].key_id,
            }).then((res) => {

                if (res.data.code === 200) {
                    console.log(res.data.msgdata.msg)
                    this.sendmsg = res.data.msgdata.msg
                    //this.$showInfoPopup("操作成功！");
                    this.scrollToBottom()
                } else {
                    this.errmsg = res.data.errmsg
                    this.errload = true

                }
                this.msgload = false
            }).catch((err) => {
                console.log(err)
                this.errmsg = err
                this.errload = true
                this.msgload = false
            })


        },
        delmsgkey(e) {
            this.delkeyid = e
            console.log(this.sendmsgkey[e])
            this.DelmsgkeyshowModal = !this.DelmsgkeyshowModal

        },
        newmsgkey() {
            //新建会话
            console.log(this.NewmsgKeyName, this.sendmsgkey)

            this.$http.post("httpchat/AddContextList", {
                "token": this.token,
                "key_id": this.sendmsgkey !== null ? this.sendmsgkey.length + 1 : 1,
                "key_name": this.NewmsgKeyName === '' ? '新建聊天窗' + this.sendmsgkey.length + 1 : this.NewmsgKeyName,

            }).then((res) => {
                console.log(res.data)
                if (res.data.code === 200) {
                    this.$showInfoPopup("新的聊天窗口已创建");
                    this.redcontextlist()

                } else {
                    this.errmsg = res.data.errmsg
                    this.errload = true
                }
            }).catch((err) => {
                console.log(err.message)
                this.errmsg = err.message
                this.errload = true
            })

            let d = {
                key_name: this.NewmsgKeyName === '' ? '新建聊天窗' + this.sendmsgkey.length + 1 : this.NewmsgKeyName,
                key_id: this.sendmsgkey !== null ? this.sendmsgkey.length + 1 : 1
            }
            this.sendmsgkey.unshift(d)
            this.NewmsgKeyName = ""
            this.NewmsgkeyshowModal = !this.NewmsgkeyshowModal

        },
        delmsgkey_handleConfirm() {

            if (this.sendmsgkey.length === 1) {
                this.DelmsgkeyshowModal = !this.DelmsgkeyshowModal
                this.$showInfoPopup("至少保留一个聊天窗口");
                return
            }
            this.$http.post("httpchat/DelContextMsg", {
                "token": this.token,
                "key_id": this.delkeyid,
            }).then((res) => {
                console.log(res.data)
                console.log('确认按钮被点击');
                if (res.data.code === 200) {
                    this.sendmsgkey.splice(this.sendmsgkeyindex, 1)
                    this.DelmsgkeyshowModal = !this.DelmsgkeyshowModal
                    this.clickmsgkey(0)
                    this.$showInfoPopup("聊天窗口已删除")
                } else {
                    this.errmsg = res.data.errmsg
                    this.errload = true
                }
            }).catch((err) => {
                console.log(err.message)
            })


        },
        redcontextlist() {
            this.ChatListLoad = true
            this.$http.post("httpchat/ReadContextLsit", {
                "token": this.token,
            }).then((res) => {
                if (res.data.code === 200) {
                    this.sendmsgkey = res.data.list.KeyList
                    if (res.data.list.KeyList.length > 0) {
                        this.clickmsgkey(0)
                    }
                } else {
                    this.errmsg = res.data.errmsg
                    this.errload = true
                }
                this.ChatListLoad = false

            }).catch((err) => {
                console.log(err.message)
                this.ChatListLoad = false
                this.errmsg = err.message
                this.errload = true
            })
        },
        toggleMenu() {
            this.menuVisible = !this.menuVisible;
        },
        closeMenu() {
            this.menuVisible = false;
        },
        autoResize() {
            const textarea = this.$refs.textarea;
            textarea.style.height = "auto";
            textarea.rows = 1
        },

        replaceString(e) {
            //console.log(e)
            if (e) {
                const regex = /`([^`]+)`/g;
                const matches = e.match(regex);
                //console.log(matches); // 输出 ["`code`"]
                return matches
            } else {
                console.log('字符串不存在');
                return ''
            }
            //const regex = /`([^`]+)`/g;
            //const matches = e.match(regex);
            //console.log(matches); // 输出 ["`code`"]
        },
        usingHelp() {
            //获取使用帮助
            this.$http.get("httpchat/usingHelp").then((res) => {
                console.log(res.data)
                console.log(this.sendmsg)
                if (res.data.code === 200) {
                    if (this.sendmsg === null) {
                        this.sendmsg = [res.data.usinghelp]
                    } else {
                        this.sendmsg.push(res.data.usinghelp)
                    }
                } else {
                    this.errmsg = res.data.errmsg
                    this.errload = true
                }
                this.menuVisible = false
                this.scrollToBottom()
            }).catch((err) => {
                console.log(err)
                this.errmsg = err
                this.errload = true
                this.menuVisible = false
            })
        },
        logout() {
            //退出登录
            console.log("logout")
            localStorage.removeItem("UserD")
            this.$router.replace({ path: '/login' })
        },
        copyText(e) {
            //复制文本到剪贴板
            const text = document.getElementById(e + '-my-text').innerText;
            const tempInput = document.createElement('input');
            tempInput.value = text;
            document.body.appendChild(tempInput);
            tempInput.select();
            document.execCommand('copy');
            document.body.removeChild(tempInput);
            this.$showInfoPopup("已复制")
        }



    }
};
</script>
  
<style>
.msgcopy {
    width: 100%;
    padding: 0 15px 10px 15px;
    color: rgb(114, 114, 132);
    font-size: 0.8rem;
    text-align: right;
}


.delp {
    position: fixed;
    right: 13px;
    bottom: 130px;
    border-radius: 50%;
    background-color: rgba(42, 43, 50, 0.6);
    z-index: 9;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;

}

.delp span {
    margin: auto;
    color: #eee;

}

.delp:active {
    opacity: 0.8;
}


.prompt {
    padding: 0 12px 12px 8px;
    color: rgb(83, 83, 98);
    font-size: 0.8rem;
    text-align: center;
}

.prompt span {
    text-decoration: underline;
    color: rgb(114, 114, 132);
}


pre {
    white-space: pre-wrap;
}

.highlight::-webkit-scrollbar {
    height: 4px;
    background-color: #181717;
    border-radius: 8px;
}

.highlight::-webkit-scrollbar-thumb {
    background-color: #322f2f;
    border-radius: 8px;
}

.highlight::-webkit-scrollbar-thumb:hover {
    background-color: #322f2f;
}

.highlight {
    width: 100%;
    background-color: rgb(29, 31, 35);
    padding: 15px;
    border-radius: 8px;
    font-size: 0.9rem;
    word-wrap: break-word;
    overflow-x: auto;
    position: relative;
    overflow-wrap: break-word;
    margin: 15px auto;
}

.footer-menu {

    color: rgb(157, 157, 173);
    display: flex;
    align-self: center;
    padding: 0 8px;
}

.clickrole {
    background-color: rgb(52, 53, 65);
}

.roleslist {
    margin: 4px 6px;
    padding: 12px;
    border-radius: 8px;
}

.roleslist span {
    font-size: 0.8rem;
    color: rgb(157, 157, 173);
}

.roles {
    padding: 8px;
}

.heightCode {
    border-radius: 8px;
    background-color: rgb(52, 53, 65);
}

input[type="radio"] {
    display: none;
}


input[type="radio"]:checked+label {
    background-color: #333;
    color: #fff;
}

.paymodal {
    width: 100%;
    padding: 10px;
}

.paymodal-head {
    width: 100%;
    text-align: center;
}

.paymodal-qrcode {
    width: 240px;
    height: 260px;
    margin: 0 auto 20px auto;
    border-radius: 8px;
    text-align: center;

    padding: 10px 0;
    background-color: rgb(52, 53, 65);
}

.paymodal-labellist {
    width: 100%;
    text-align: center;
}

.paymodal-label {
    display: inline-block;
    padding: 8px;
    background-color: rgb(52, 53, 65);
    color: #eee;
    border-radius: 8px;
    margin: 0 2px;
    transform: scale(1);
    transition: transform .6s;
    font-size: 0.8rem;

}

.paymodal-labelC {
    display: inline-block;
    padding: 8px;
    background-color: #3366ff;
    color: #eee;
    border-radius: 8px;
    margin: 0 2px;
    transform: scale(1);
    transition: transform .6s;

}

.paymodal-labelC:active {
    transform: scale(0.85);
}

.paymodal-price {
    font-size: 1.4rem;
    padding: 20px;
}

.accountButton {
    width: 100%;
    display: flex;
    margin-top: 26px;
}

.accountButton button {
    width: 100%;
    padding: 15px;
    margin: 0 3px;
    background-color: #3366ff;
    border-radius: 8px;
    color: #eee;
    border: 0;
    transform: scale(1);
    transition: transform .6s;
}

.accountButton button:hover {
    transform: scale(0.96);
}

.accountButton button:active {
    transform: scale(0.85);
}

.headmodal {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
}

.accmsg {
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-self: center;
    font-size: 1rem;
}


.errmsg {
    position: fixed;
    top: 5%;
    left: 0;
    right: 0;
    z-index: 999999;
    text-align: center;
    margin: 40px 10%;
    padding: 20px;
    color: #eee;
    border-radius: 6px;
    background-color: rgba(32, 33, 35, 0.9);
    justify-content: space-between;
    align-self: center;
    display: flex;
    box-shadow: #181717 0 0 2px;
}

.errmsg span {
    font-size: 1.3rem;
}

.delmsgkey {
    width: 28px;
    height: 28px;
    padding: 6px;
    margin: 8px 0 8px 8px;
    border-radius: 4px;
    text-align: center;
}

.delmsgkey:hover {
    background-color: rgb(68, 70, 84);
}


.multiline-text {
    white-space: pre-wrap;
}



.message-row-loads {
    font-size: 12px;
    display: inline-block;
    animation: loadings 1.2s ease-in-out infinite;
    color: #8d8e91;
}

.message-row-loads:nth-child(1) {
    animation-delay: 0.6s;
}

.message-row-load {
    font-size: 12px;
    display: inline-block;
    animation: loading 1.2s ease-in-out infinite;
    color: #8d8e91;
}

.message-row-load:nth-child(1) {
    animation-delay: 0.3s;
}

.message-row-load:nth-child(2) {
    animation-delay: 0.4s;
}

.message-row-load:nth-child(3) {
    animation-delay: 0.5s;
}

@keyframes loadings {
    0% {
        transform: scale(0.5);
        opacity: 0.0;
    }

    100% {
        transform: scale(0.5);
        opacity: 1;
    }
}

@keyframes loading {
    0% {
        transform: scale(0.2);
        opacity: 0.2;
    }

    50% {
        transform: scale(1.0);
        opacity: 1.0;
    }

    100% {
        transform: scale(0.2);
        opacity: 0.2;
    }
}

body {
    overflow-x: hidden;
    padding-bottom: env(safe-area-inset-bottom);
    background-color: rgb(52, 53, 65);
}

body::-webkit-scrollbar {
    width: 8px;
    background-color: #181717;
}

body::-webkit-scrollbar-thumb {
    background-color: #322f2f;
    border-radius: 8px;
}

body::-webkit-scrollbar-thumb:hover {
    background-color: #322f2f;
    border-radius: 8px;
}

.Chatbody {
    background-color: rgb(68, 70, 84);
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 50px);
    font-size: 18px;
    

}

.fi {
    font-size: 1rem;
}


.main {
    flex-grow: 1;
    display: flex;
    position: relative;
}


.newchat {
    width: calc(100% - 80px);
    padding: 12px 15px;
    margin: 10px;
    margin-left: 20px;
    border: 1px solid rgb(68, 70, 84);
    border-radius: 6px;
    background-color: rgb(32, 33, 35);
    color: #fff;
    text-align: left;
    font-size: 1rem;
    cursor: pointer;
}

.head {
    display: flex;
    /* 使用Flexbox */
    align-items: center;
    /* 垂直居中 */
    justify-items: self-end;
    line-height: 46px;
    height: 46px;
    margin: 10px 0;

}


.newchatspan {
    padding-left: 12px;

}

.newchat:hover {
    background-color: rgb(43, 44, 47);
}


.mainLeftBottom {
    position: absolute;
    bottom: 10px;
    width: 100%;
}


.menuBottom {
    border-top: 1px solid rgb(68, 70, 84);
    padding-top: 10px;
}

.menuBottom ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.menuBottom li {
    padding: 0;
    margin: 0;
    width: 100%;
    padding: 8px 25px;
    line-height: 28px;
    border-radius: 8px;

}

.menuBottom li span {
    margin-right: 12px;
    color: #eee;
}

.menuBottom li:hover {
    background-color: rgb(42, 43, 50);
}

.menuBottom li a {
    color: #eee;
    text-decoration: none;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.menu {
    width: 100%;
    height: calc(100%);
    background-color: rgb(32, 33, 35);
    box-shadow: 1px 0 2px rgba(0, 0, 0, 0.1);
    position: fixed;
    left: 0;
    top: 0;
    z-index: 50;
    overflow-y: auto;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;

}

.menu-item {
    width: 100%;
    display: flex;
    justify-items: center;
    align-self: center;
}

.menu-items {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    align-self: center;

}

.menu.show {
    transform: translateY(0%);
}

.menu ul {
    list-style: none;
    margin-left: 10px;
    margin-right: 10px;
}

.menu li {
    padding: 0 15px;
    margin: 10px;
    height: 48px;
    border-radius: 8px;
    margin-bottom: 8px;
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    align-self: center;


}

.menu li span {
    color: #eee;
}

.menu li:hover {
    background-color: rgb(42, 43, 50);
}

.menu-li-click {
    background-color: rgb(42, 43, 50);
}

.menu li a {
    color: #eee;
    text-decoration: none;
    margin-left: 10px;
    word-wrap: break-word;
    overflow-wrap: break-word;
}

.menu nav {
    width: 100%;
    height: calc(100% - 340px);
    overflow-y: auto;


}

.menu nav::-webkit-scrollbar {
    width: 5px;
    background-color: #181717;
}

.menu nav::-webkit-scrollbar-thumb {
    background-color: #322f2f;
    border-radius: 8px;
}

.menu nav::-webkit-scrollbar-thumb:hover {
    background-color: #322f2f;
    border-radius: 8px;
}

.menu-close-button {
    background-color: transparent;
    border: none;
    color: #eee;
    font-size: 30px;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
}

.menu-close-button:hover {
    color: #fff;
}

.chat-container {
    width: calc(100%);
    display: flex;
    flex-direction: column;
    margin-left: 0;
}

.chat-header {
    background-color: rgb(52, 53, 65);
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.2);
    color: rgb(224, 222, 222);
    padding: 10px;
    display: none;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 22;
}

.chat-header h3 {
    margin: 0;
    padding: 5px 10px;
    font-weight: 400;
}

.chat-header .chat-header-menu-icon {
    color: rgb(224, 222, 222);
    padding: 5px 10px;
    border-radius: 3px;
    cursor: pointer;
    font-size: 1.2rem;
}

.chat-header .chat-header-menu-icon:hover:active {
    background-color: rgb(52, 53, 65);
}

.chat-footer {
    background-color: rgb(52, 53, 65);
    padding: 10px 10px 25px 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
   
}

.input-form {
    display: flex;
    flex-grow: 1;
    margin-right: 10px;
    position: relative;

}

.input-message {
    flex-grow: 1;
    border: 0px;
    border-radius: 5px;
    padding: 10px;
    margin-right: 45px;
    margin-left: 10px;
    /* 留出发送按钮所需的空间 */
    outline: none;
    background-color: rgb(64, 65, 79);
    color: #eee;
    resize: none;
    /* 禁止手动调整大小 */
    height: auto;
    overflow: hidden;
    max-height: 80px;
    font-size: 1rem;
}


.input-message::-webkit-scrollbar {
    width: 5px;
    background-color: #181717;
    border-radius: 8px;
}

.input-message::-webkit-scrollbar-thumb {
    background-color: #322f2f;
    border-radius: 8px;
}

.input-message::-webkit-scrollbar-thumb:hover {
    background-color: #322f2f;
    border-radius: 8px;
}

/* .input-message:focus {
    border-color: #4caf50;
} */

.send-button {
    border: none;
    font-size: 1.4rem;
    padding: 10px;
    cursor: pointer;
    outline: none;
    margin-left: 10px;
    transform: translateY(-50%);
    background-color: rgb(52, 53, 65);
    color: rgb(157, 157, 173);
    position: absolute;
    top: 50%;
    right: 0;
}


.send-button span {
    font-size: 1.2rem;
}

.send-button:active {
    color: #3366ff;
    background-color: rgb(52, 53, 65);

}

.send-button:hover {
    color: #eee;
    background-color: rgb(52, 53, 65);
}

.message-container {
    margin: 16px 5px 0 5px;
    padding-bottom: 90px;
    padding-top: 36px;
}

.message {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

}

.message-text {
    max-width: 100%;
    padding: 16px;
    border-radius: 12px;
    line-height: 1.4;
    text-align: left;
    font-size: 1rem;
}

.message-sent {
    max-width: 71%;
    align-self: flex-end;
    background-color: rgb(52, 53, 65);
    color: #fff;
    border-radius: 8px;
    overflow-wrap: break-word;
}

.message-received {
    max-width: 71%;
    align-self: flex-start;
    background-color: rgb(64, 65, 79);
    color: #eee;
    border-radius: 8px;
    overflow-wrap: break-word;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin: 0 8px;
}

.message-row {
    display: flex;
    justify-content: flex-start;

}

.message-sent {
    margin-left: auto;
}

.message-received {
    margin-right: auto;
}

@media screen and (min-width: 769px) {
    .errmsg {
        left: 250px;
    }

    .input-message {
        padding: 20px 10px;
        margin: 0px 50px 0px 0;
        max-height: 150px;
    }

    .message-text {
        max-width: 100%;
    }

    .menu-close-button {
        display: none;
    }

    .menu ul {
        margin-top: 20px;
    }

    .chat-header {
        display: none;
    }

    .chat-footer {
        left: 250px;
        padding: 20px;
    }

    .menu {
        width: 250px;
        position: fixed;
        top: 0;
        transform: translateY(0);
        transition: none;
    }

    .newchat {
        width: calc(100% - 40px);
        padding: 12px 15px;
        margin: 10px 20px;
        font-size: 1rem;
    }

    .menu nav {
        height: calc(78% - 26px);
        overflow-y: auto;
        margin-top: 0;
    }

    .mainLeftBottom {
        bottom: 10px;
    }

    .menu.show {
        transform: translateY(0);
    }

    .chat-container {
        margin-left: 250px;
    }

    .chat-header::after {
        display: none;
    }

    .Chatbody {
        font-size: 14px;
        min-height: calc(100vh - 70px);
    }

    .message-container {
        max-width: 860px;
        padding: 20px 20px 120px 20px;
        margin: 0 auto 0 auto;

    }

    .message-sent {
        max-width: calc(100% - 110px);
    }

    .message-received {
        max-width: calc(100% - 110px);

    }

    .message-text {
        font-size: 1rem;
    }

    .roles {
        padding: 8px;
        display: block;
        justify-content: none;
        align-self: none;
    }

    .roleslist {
        margin-bottom: 10px;
    }

    .footer-menu {
        display: none;
    }

    .delp {
        display: none;
    }
}
</style>
  