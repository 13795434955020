<!-- InfoPopup.vue -->
<template>
    <transition name="fade">
      <div v-if="visible" class="info-popup">
        {{ message }}
      </div>
    </transition>
  </template>
  
  <script>
  export default {
    props: {
      message: {
        type: String,
        required: true,
      },
      duration:{
        type:Number,
        default:3000,
      }
    },
    data() {
      return {
        visible: false,
      };
    },
    methods: {
      show(ts = this.duration) {
        this.visible = true;
        setTimeout(() => {
          this.visible = false;
        }, ts);
      },
    },
  };
  </script>
  
  <style scoped>
  .info-popup {
    background-color: rgba(0, 0, 0, 0.8);
    color: #eee;
    padding: 10px 20px;
    border-radius: 6px;
    position: fixed;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 999999;
  }
  
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  </style>
  