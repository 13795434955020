// infoPopupPlugin.js
import Vue from "vue";
import InfoPopup from "../../common/InfoPopup.vue";

const InfoPopupConstructor = Vue.extend(InfoPopup);

const infoPopupPlugin = {
  install(Vue) {
    Vue.prototype.$showInfoPopup = function (message, duration = 3000) {
      const instance = new InfoPopupConstructor({
        propsData: { message },
      });

      instance.$mount();
      document.body.appendChild(instance.$el);

      instance.show(duration);

      setTimeout(() => {
        instance.$destroy();
        document.body.removeChild(instance.$el);
      }, duration + 1000);
    };
  },
};

export default infoPopupPlugin;
