<template>
  <div class="custom-modal" v-if="show">
    <div class="custom-modal-overlay" @click="close"></div>
    <div class="custom-modal-content">
      <div class="custom-modal-content-top">
        <div v-if="title">{{ title }}</div>
        <div @click="close"><span class="fi fi-rr-cross"></span></div>
      </div>
      <div class="custom-modal-content-slot">
        <slot></slot>
      </div>
      
      <div v-if="showButtons" class="model-buttom">
        <button class="model-buttom-button-cancel" @click="cancel">{{ cancelButtonText }}</button>
        <button class="model-buttom-button-confirm" @click="confirm">{{ confirmButtonText }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    confirmButtonText: {
      type: String,
      default: '确认',
    },
    cancelButtonText: {
      type: String,
      default: '取消',
    },
  },
  methods: {
    close() {
      this.$emit('update:show', false);
    },
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
  },
};
  </script>
  
  <style scoped>
  .custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .custom-modal-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .custom-modal-content {
    position: relative;
    width: 70%;
    background-color: rgb(68, 70, 84);
    padding: 20px;
    border-radius: 8px;
    z-index: 10000;
    color: #eee;

  }
  .custom-modal-content-top{
    display: flex;
    justify-content: space-between;
 
    padding-bottom:10px;
    font-size: 1rem;
  }
  .custom-modal-content-top span{
    font-size: 0.8rem;
  }
  .custom-modal-content-slot{
    padding: 10px 0;
  }

  .model-buttom {
    text-align: right;
    margin-top: 10px;
}

.model-buttom-button-confirm {
    padding: 5px 6px;
    border: 1px solid rgb(68, 70, 84);
    border-radius: 5px;
    background-color: rgb(32, 33, 35);
    color: #fff;
    margin-left: 10px;
}
.model-buttom-button-confirm:active{
  opacity: 0.8;
}
.model-buttom-button-cancel {
    padding: 5px 6px;
    border: 1px solid rgb(68, 70, 84);
    border-radius: 5px;
    background-color: rgb(89, 90, 103);
    color: #fff;
    margin-left: 10px;
}
.model-buttom-button-cancel{
  opacity: 0.8;
}

@media screen and (min-width: 769px) {
  .custom-modal-content {
    max-width: 360px;
  }
}
  </style>
  