function isValidJSON(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}


function checkCharacterType(char) {
  if (/[a-zA-Z]/.test(char)) {
    return "字母";
  } else if (/\d/.test(char)) {
    return "数字";
  } else if (/[\u4e00-\u9fa5]/.test(char)) {
    return "汉字";
  } else {
    return "其他";
  }
}

function truncateText(text, maxLength) {
  let lengthCounter = 0;
  let index = 0;
  while (lengthCounter < maxLength && index < text.length) {
    const charType = checkCharacterType(text[index]);
    if (charType === "汉字") {
      lengthCounter += 2;
    } else {
      lengthCounter += 1;
    }
    index++;
  }

  if (index < text.length) {
    return text.slice(0, index) + "...";
  } else {
    return text;
  }
}

function timestampToTime(timestamp,n) {

  const date = new Date(timestamp * 1000); // 时间戳单位为秒，需要乘以1000转换为毫秒
  const year = date.getFullYear();
  const month = addZero(date.getMonth() + 1);
  const day = addZero(date.getDate());
  const hour = addZero(date.getHours());
  const minute = addZero(date.getMinutes());
  const second = addZero(date.getSeconds());

if(n===0){
  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
} 
if(n===1){
  return `${year}-${month}-${day} ${hour}:${minute}`;
}
if(n===2){
  return `${year}-${month}-${day} ${hour}`;
} 
if(n===3){
  return `${year}${month}${day}${hour}${minute}${second}`;
} 
  
}

function addZero(num) {
  return num < 10 ? `0${num}` : num;
}


export default {
  isValidJSON,
  truncateText,
  timestampToTime
}