<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',

  data() {
    return {
    }
  },
  mounted() {
    let varua = navigator.userAgent.toLowerCase();
    if (varua.match(/MicroMessenger/i) != "micromessenger") {
      this.Pcowx = true
      this.currentComponent = "LoginComponent";
    } else {
      this.currentComponent = "ChatApp";
    }
  }
};
</script>
<style>
@import "assets/style/css/styles.css";
@import "assets/style/webfonts/uicons-regular-rounded.css";
</style>