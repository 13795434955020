<template>
  <div class="custom-modal" ref="custommodal" v-if="show" @click="close">
    <div class="custom-modal-overlay" @click.stop="">
      <div class="custom-modal-content-top">
        <div v-if="title">{{ title }}</div>
        <div style="font-size: 1.2rem;" @click="close"><span class="fi fi-rr-cross"></span></div>
      </div>
      <div class="custom-modal-content-slot">
        <slot></slot>
      </div>
    
    </div>

  </div>
</template>
  
<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
  },
  methods: {
    close() {
      
      this.$refs.custommodal.classList.add('hide');
      const _this = this
      setTimeout(function(){
        _this.$emit('update:show', false);
      },200)
    },
    confirm() {
      this.$emit('confirm');
    },
    cancel() {
      this.$emit('cancel');
      this.close();
    },
  },
};
</script>
    
<style scoped>
.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  animation: fade-in-out 0.2s ease-in-out forwards;
}

@keyframes fade-in-out {
  0% { opacity: 0; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
}

.custom-modal.hide {
  animation: fade-in-out-reverse 0.2s ease-in-out forwards;
}

@keyframes fade-in-out-reverse {
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 0; }
}
.custom-modal-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 25px;
  margin: 0 auto;
  background-color: rgb(32, 33, 35);
  color: #eee;
}

.custom-modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgb(68, 70, 84);
  padding: 20px;
  border-radius: 8px;
  z-index: 10000;
  color: #eee;

}

.custom-modal-content-top {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  font-size: 1.2rem;
  
}

.custom-modal-content-top span {
  font-size: 0.8rem;
}

.custom-modal-content-slot {
  padding: 10px 0;
}

.model-buttom {
  text-align: right;
  margin-top: 10px;
}

.model-buttom-button-confirm {
  padding: 5px 6px;
  border: 1px solid rgb(68, 70, 84);
  border-radius: 5px;
  background-color: rgb(32, 33, 35);
  color: #fff;
  margin-left: 10px;
}

.model-buttom-button-confirm:active {
  opacity: 0.8;
}

.model-buttom-button-cancel {
  padding: 5px 6px;
  border: 1px solid rgb(68, 70, 84);
  border-radius: 5px;
  background-color: rgb(89, 90, 103);
  color: #fff;
  margin-left: 10px;
}

.model-buttom-button-cancel {
  opacity: 0.8;
}

@media screen and (min-width: 769px) {
  .custom-modal-content {
    max-width: 360px;
  }

  .custom-modal-overlay {
    width: 250px;
  }
  .custom-modal-content-top {
    font-size: 1rem;
  }
}
</style>
    